<template>
  <div>
    <div :class="isPc ? 'pc-containers' : 'mb-containers'" :style="isPc ? 'height: 100%' : ''">
      <div :class="isPc ? 'pc-contents' : 'mb-contents'">
        <v-card-text :class="isPc ? 'text-center' : ''">
          <p>{{ provider.name }} is <span class="bold-f">processing your request</span>,</p> please be patient and wait
          until it is done, thank you.
        </v-card-text>
        <v-card-actions v-if="overlay">
          <v-btn x-large color="grey" text fab :loading="true"></v-btn>
        </v-card-actions>
        <v-card-text v-else :class="isPc ? 'overflow-auto m-auto' : 'width-full'" :style="isPc ? 'height: calc(100vh - 370px); width: calc(75%);' : 'height: calc(100vh - 56px - 48px - 76px - 32px - 5px)'">
          <iframe class="position-rel height-full width-full no-border" id="IFrameHolder" frameborder="0"
                  :src="decisionLogicUrl"></iframe>
        </v-card-text>
      </div>
    </div>
    <v-dialog
        persistent
        max-width="480"
        v-model="showModal"
    >
      <v-card flat style="padding: 20px" class="b-r-16">
        <v-btn @click="showModal=false" icon
               style="position: absolute; top:0px; right: 0px">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-title :style="`color: ${$vuetify.theme.themes.light.primary}`">
          Hmmm, looks like we couldn't verify your bank information
        </v-card-title>
        <v-card-text>
          That's ok, to continue, please select one of the options below.
        </v-card-text>
        <v-card-actions>
          <v-btn
              block
              large
              color="primary"
              class="width-full"
              v-if="goBank"
              @click="modifyBankInformation"
          >
            MODIFY ABA/ACCOUNT NUMBER
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="showTryAgain">
          <v-btn
              block
              x-large
              color="primary"
              class="width-150 m-0"
              @click="showModal = false"
          >
            TRY AGAIN
          </v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn
              block
              x-large
              color="primary"
              class="width-150 m-0"
              @click="toAgentReview"
          >
            GIVE ME A CALL
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DataType, Tool } from '../../js/core'
import { OriginationApi } from '../../api/application'
import { IbvApi } from '../../api/ibv'

const tool = new Tool()
export default {
  props: {
    isPc: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  data () {
    return {
      page: DataType.PAGES.IBV,
      showTryAgain: true,
      goBank: true,
      showModal: false,
      ibv: {},
      loanId: '',
      requestCode: '',
      loan: {},
      overlay: true,
      progress: 4,
      step: 'bank',
      decisionLogicUrl: '',
      count: 0,
      timer: 10,
      snackbar: false,
      firstName: '',
      timeOut: 0,
      requestId: '',
      forceQuit: 'notForceQuit',
      provider: {}
    }
  },
  methods: {
    closeSnackbar () {
      const _this = this
      _this.snackbar = false
      window.clearInterval(_this.timeOut)
    },
    disableDialog () {
      this.count = 1
      this.showModal = false
    },
    modifyBankInformation () {
      this.showModal = false
      if (this.isPc) {
        this.$router.push(DataType.PAGES.BANK.addressPC)
      } else {
        this.$router.push(DataType.PAGES.BANK.address)
      }
    },
    toAgentReview () {
      const _this = this
      OriginationApi.updateOrigination({
        feature: 'loan-status',
        data: {
          loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
          loanStatus: DataType.LoanStatus.AGENT_REVIEW.value
        }
      }, function () {
        _this.$router.push('/instant/notification/-100')
      }, function () {
        _this.$router.push('/instant/notification/-100')
      })
    },
    checkRequestCodeStatus () {
      const _this = this
      const timeIn = setInterval(function () {
        IbvApi.getStatus({ requestId: _this.requestId }, function (status) {
          if (status.status === 3) {
            _this.updateLoanIbvData(status.status)
            if (_this.forceQuit === 'notForceQuit') {
              _this.next()
            } else {
              _this.$router.push('/instant/notification/-100')
            }
            clearInterval(timeIn)
          } else {
            if (_this.count === 50) {
              _this.showModal = true
              _this.count = 0
            }
            _this.count++
          }
        })
      }, 5 * 1000)
    },
    next () {
      const stepMessage = {
        currect: DataType.PAGES.IBV,
        next: DataType.PAGES.DL_VERIFY
      }
      this.$eventBus.$emit('sendStepToSloth', stepMessage)
      OriginationApi.enteredAndLeft(
        localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
        DataType.PAGES.IBV.step,
        DataType.PAGES.DL_VERIFY.step,
        localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
      )
      if (this.isPc) {
        this.$router.push(DataType.PAGES[this.page.next].addressPC)
      } else {
        this.$router.push(DataType.PAGES[this.page.next].address)
      }
    },
    updateLoanIbvData (status) {
      const updateIbvData = {
        requestCode: this.requestCode,
        ibvStatus: status,
        loanId: this.loanId,
        requestId: this.requestId,
        contactType: DataType.IBVSelectContactType[0].value,
        provider: this.provider.code
      }
      OriginationApi.updateOrigination({ feature: 'ibv', data: updateIbvData }, function (result) {
      })
    },
    getIbvPageData () {
      const _this = this
      _this.overlay = false
      _this.decisionLogicUrl = 'https://widget.decisionlogic.com/Service.aspx?requestCode=' + _this.requestCode
      _this.checkRequestCodeStatus()
    }
  },
  mounted () {
    const _this = this
    _this.provider = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PROVIDER))
    _this.$store.commit('setCurrentPage', this.page)
    _this.$store.commit('setOverlay', false)
    _this.loanId = localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID)
    const personal = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL))
    _this.firstName = personal.firstName
    this.$eventBus.$on('forceQuit', target => {
      this.forceQuit = target
      this.$router.push('/instant/notification/-100')
    })
    OriginationApi.getLoanById({ loanId: this.loanId, feature: 'IBV,BANK' }, function (result) {
      _this.loan = result
      if (tool.isEmpty(result) || tool.isEmpty(result.requestCode)) {
        const createRequestCodeData = {
          portfolioId: result.portfolioId,
          contactType: DataType.IBVSelectContactType[0].value,
          loanId: parseInt(_this.loanId),
          providerEntity: _this.provider.code
        }
        IbvApi.createRequestCode(createRequestCodeData, function (requestResult) {
          localStorage.setItem(DataType.COOKIE_KEY.REQUEST_CODE, btoa(requestResult.requestCode))
          localStorage.setItem(DataType.COOKIE_KEY.REQUEST_ID, btoa(requestResult.requestId))
          _this.requestCode = requestResult.requestCode
          _this.requestId = requestResult.requestId
          _this.loan.requestCode = _this.requestCode
          _this.loan.ibvStatus = 0
          _this.updateLoanIbvData(0)
          _this.getIbvPageData()
        }, function (res) {

        })
      } else {
        _this.requestCode = result.ibvDataList[0].requestCode
        _this.requestId = result.ibvDataList[0].requestId
        localStorage.setItem(DataType.COOKIE_KEY.REQUEST_CODE, btoa(_this.requestCode))
        localStorage.setItem(DataType.COOKIE_KEY.REQUEST_ID, btoa(_this.requestId))
        _this.getIbvPageData()
      }
    }, function (res) {

    })
  }
}
</script>
<style lang="css">
</style>
