import { ajax } from '@/js/extension'
import axios from 'axios'

const BASE_URL = '/gateway/platform-lmsapi/loan-resource'

const IbvApi = {
  createRequestCode (data, success, failure) {
    return ajax.post(
      BASE_URL + '/create-request-code',
      data,
      success,
      failure
    )
  },
  // getDLPage (data, success, failure) {
  //   return ajax.get('/ibv/get-page/{0}'.format(data), null, success, failure)
  // },
  getDLPage (data, success, failure) {
    return axios.get('/ibv/get-page/{0}'.format(data), {
      transformRequest: (data, headers) => {
        delete headers.common['Access-Token']
        delete headers.common['L-I']
        return data
      }
    }).then(result => resultHandler(result, Promise.resolve, Promise.reject, success, failure))
  },
  getStatus (data, success, failure) {
    return ajax.get(BASE_URL + '/get-request-status', data, success, failure)
  }
}

function resultHandler (result, resolve, reject, success, failure) {
  const resultData = result.data
  if (result.status === 200) {
    if (resultData.code < 0) {
      if (failure) {
        failure(resultData)
      } else {
        if (resultData.code === -20002) {
          window.open('/#/notification/-100')
        }
      }
      return Promise.reject(result)
    } else {
      if (success) {
        success(resultData.data)
      }

      return Promise.resolve(resultData.data)
    }
  } else {
    if (failure) {
      failure(resultData)
    }

    return Promise.reject(result.statusText)
  }
}

export { IbvApi }
